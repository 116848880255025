// frontend/src/components/Dashboard.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
    Box,
    Typography,
    Grid,
    Paper,
    Popover,
    IconButton,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Link,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
    Chip,
    Tooltip,
    TableContainer,
    LinearProgress,
    useMediaQuery,
} from '@mui/material';
import useDocumentTitle from '../hooks/useDocumentTitle'; // Import the custom hook
import StorageIcon from '@mui/icons-material/Storage';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CancelIcon from '@mui/icons-material/Cancel';
import AssessmentIcon from '@mui/icons-material/Assessment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AlarmIcon from '@mui/icons-material/Alarm';
import WarningIcon from '@mui/icons-material/Warning'; // Added for blinking icon
import { ErrorOutline } from '@mui/icons-material';
import Layout from './Layout';
import {
    getDashboardSummary,
    getRequiredDispatchTodayOrders,
    getRequiredDispatchTodayOrdersExcludeExOrders,
} from '../services/api';
import LoadingIndicator from './LoadingIndicator';
import IssueDialog from './IssueDialog';
import SummaryCardWithAreaChart from './Cards/SummaryCardWithAreaChart';
import SummaryCardWithPieChart from './Cards/SummaryCardWithPieChart';
import RequiredDispatchProgressCard from './Cards/RequiredDispatchProgressCard';
import { format, subDays } from 'date-fns';
import { useTheme, styled } from '@mui/material/styles';
import { getColorByPercentage, getDispatchColor, getChipStyles } from '../utils/colorUtils';
import ClientTable from './ClientTable'; // Ensure ClientTable is updated separately

// Styled component for blinking attention icon
const BlinkingWarningIcon = styled(WarningIcon)(({ theme }) => ({
    color: theme.palette.error.main,
    animation: 'blinker 1s linear infinite',
    '@keyframes blinker': {
        '50%': { opacity: 0 },
    },
    marginLeft: theme.spacing(0.5),
}));

const Dashboard = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // State Variables
    const [ordersData, setOrdersData] = useState({ summary: {}, clients: [] });
    const [loading, setLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverClient, setPopoverClient] = useState(null);
    const [dateTime, setDateTime] = useState('');
    const [intervalTime, setIntervalTime] = useState(15000); // Default 15s interval
    const [error, setError] = useState(null); // For error handling
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedClientName, setSelectedClientName] = useState('');
    const [dispatchOrders, setDispatchOrders] = useState([]);
    const [dispatchLoading, setDispatchLoading] = useState(false);
    const [dispatchError, setDispatchError] = useState(null);
    const [openIssueDialog, setOpenIssueDialog] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);

    // Add a ref for the main container
    const mainContainerRef = useRef(null);

    // Additional State for Cutoff Times Popover
    const [cutoffPopoverAnchorEl, setCutoffPopoverAnchorEl] = useState(null);
    const [cutoffClient, setCutoffClient] = useState(null);

    // Interval Options for Reload
    const intervalOptions = [
        { label: '5 seconds', value: 5000 },
        { label: '15 seconds', value: 15000 },
        { label: '1 minute', value: 60000 },
        { label: '5 minutes', value: 300000 },
        { label: '10 minutes', value: 600000 },
        { label: '30 minutes', value: 1800000 },
        { label: '1 hour', value: 3600000 },
    ];

    // State and Effect for Toggling Document Title
    const [titleToggle, setTitleToggle] = useState(true); // true: "IV Linx", false: "X RDO"

    useEffect(() => {
        // Toggle the title every 5 seconds
        const titleInterval = setInterval(() => {
            setTitleToggle((prev) => !prev);
        }, 5000); // 5000ms = 5 seconds

        // Cleanup interval on component unmount
        return () => clearInterval(titleInterval);
    }, []);

    // Set the document title using the custom hook
    useDocumentTitle(
        titleToggle
            ? 'IV Linx'
            : `${ordersData.summary.requiredDispatchedToday || 0} RDO`
    );

    // Update Current Date and Time Every Second
    useEffect(() => {
        const updateDateTime = () => {
            const today = new Date();
            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            };
            setDateTime(today.toLocaleDateString('en-UK', options));
        };

        updateDateTime();
        const intervalId = setInterval(updateDateTime, 1000);
        return () => clearInterval(intervalId);
    }, []);

    // Fetch Orders Data from Backend
    const fetchOrdersData = useCallback(async () => {
        try {
            const response = await getDashboardSummary();
            // Assuming response is an object with 'summary' and 'clients' fields
            // e.g., { summary: { ... }, clients: [ ... ] }
            setOrdersData(response);
            setError(null); // Reset error on successful fetch

        } catch (err) {
            console.error('Error fetching orders data:', err);
            setError('Failed to fetch dashboard data. Please try again later.');
        } finally {
            setLoading(false);
        }
    }, []);

    // Initial Data Fetch
    useEffect(() => {
        fetchOrdersData();
    }, [fetchOrdersData]);

    // Manage Reload Based on Selected Interval
    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchOrdersData();
        }, intervalTime);

        // Cleanup on Unmount or when intervalTime changes
        return () => clearInterval(intervalId);
    }, [fetchOrdersData, intervalTime]);

    // Handle Popover Open
    const handlePopoverOpen = (event, client) => {
        setAnchorEl(event.currentTarget);
        setPopoverClient(client);
    };

    // Handle Popover Close
    const handlePopoverClose = () => {
        setAnchorEl(null);
        setPopoverClient(null);
    };

    const openPopover = Boolean(anchorEl);

    // Format DateTime
    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date)) return 'Invalid Date';

        const optionsDate = { year: 'numeric', month: 'short', day: 'numeric' };
        const optionsTime = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };

        const formattedDate = date.toLocaleDateString(undefined, optionsDate); // e.g., '29 Oct 2024'
        const formattedTime = date.toLocaleTimeString(undefined, optionsTime); // e.g., '22:58:57'

        return `${formattedDate} ${formattedTime}`; // '29 Oct 2024 22:58:57'
    };

    useEffect(() => {
        if (modalOpen && selectedClientName) {
            loadDispatchOrders();
        }
    }, [modalOpen, selectedClientName]);

    // Load Dispatch Orders
    const loadDispatchOrders = useCallback(async () => {
        if (!selectedClientName) return;

        setDispatchLoading(true);
        setDispatchError(null);

        try {
            const orders = await getRequiredDispatchTodayOrdersExcludeExOrders(selectedClientName);
            // Sort the orders by createdAt in ascending order
            const sortedOrders = orders.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
            setDispatchOrders(sortedOrders);
        } catch (error) {
            setDispatchError('Failed to load orders. Please try again.');
            console.error(`Error fetching required dispatch today orders for ${selectedClientName}:`, error);
        } finally {
            setDispatchLoading(false);
        }
    }, [selectedClientName]);

    const handleDispatchTodayClick = (clientName) => {
        setSelectedClientName(clientName);
        setModalOpen(true);
    };


    // Handle Cutoff Time Icon Click
    const handleCutoffTimeClick = (event, client) => {
        setCutoffPopoverAnchorEl(event.currentTarget);
        setCutoffClient(client);
    };

    const handleCutoffPopoverClose = () => {
        setCutoffPopoverAnchorEl(null);
        setCutoffClient(null);
    };

    const openCutoffPopover = Boolean(cutoffPopoverAnchorEl);

    // Handle Add/Edit Issue Click
    const handleAddIssueClick = (order) => {
        setSelectedOrder(order);
        setOpenIssueDialog(true);
    };

    // Handle IssueDialog Close
    const handleIssueDialogClose = () => {
        setOpenIssueDialog(false);
        setSelectedOrder(null);
    };

    // Handle Issue Added or Updated
    const handleIssueAdded = () => {
        // Refresh the dispatch orders to reflect changes
        loadDispatchOrders();
    };


    // Handle modal close and set focus back to main container
    const handleModalClose = () => {
        setModalOpen(false);
        setSelectedClientName('');
        setDispatchOrders([]);
        setDispatchError(null);

        // Set focus back to the main container
        if (mainContainerRef.current) {
            mainContainerRef.current.focus();
        }
    };

    // If Data is Loading, Show Loading Indicator
    if (loading) {
        return (
            <Layout>
                <LoadingIndicator />
            </Layout>
        );
    }

    // Prepare Summary Data for Cards
    const { summary, clients } = ordersData;

    // Prepare Data for TinyAreaCharts
    const prepareTinyAreaChartData = (dataArray) => {
        // Generate dates for the last 7 days including today
        const today = new Date();
        const last7Days = Array.from({ length: 7 }, (_, index) =>
            subDays(today, 6 - index)
        ); // Array of Date objects from 6 days ago to today

        return dataArray.map((value, index) => ({
            date: format(last7Days[index], 'dd MMM'), // e.g., '26 Oct'
            value,
        }));
    };

    // Function to format cutoff time
    const formatCutoffTime = (time) => {
        if (time) {
            // Append 'Z' to indicate UTC time if not already present
            const utcTime = time.endsWith('Z') ? time : `${time}Z`;
            const date = new Date(utcTime);
            if (!isNaN(date)) {
                return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZone: 'UTC' });
            }
        }
        return 'N/A';
    };

    return (
        <Layout>
            <Box
                ref={mainContainerRef}
                tabIndex="-1" // Make the Box focusable
                sx={{
                    p: { xs: 2, sm: 3 },
                    backgroundColor: '#f5f5f5',
                    minHeight: '100vh',
                }}
            >
                {/* Header Section */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        justifyContent: 'space-between',
                        alignItems: { xs: 'flex-start', sm: 'center' },
                        mb: 3,
                    }}
                >
                    <Box>
                        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                            Summary
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            {dateTime}
                        </Typography>
                    </Box>
                    {/* Reload Interval Selector */}
                    <FormControl variant="outlined" sx={{ minWidth: 150, mt: { xs: 2, sm: 0 } }}>
                        <InputLabel id="reload-interval-label">Reload Interval</InputLabel>
                        <Select
                            labelId="reload-interval-label"
                            id="reload-interval"
                            value={intervalTime}
                            onChange={(e) => setIntervalTime(e.target.value)}
                            label="Reload Interval"
                        >
                            {intervalOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                {/* Display Error Message if Exists */}
                {error && (
                    <Box sx={{ mb: 3 }}>
                        <Typography variant="body1" color="error">
                            {error}
                        </Typography>
                    </Box>
                )}

                {/* Main Grid Layout */}
                <Grid container spacing={2} sx={{ mb: 4 }} alignItems="stretch">
                    {/* Fulfilment Accuracy Rate */}
                    <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
                        <SummaryCardWithPieChart
                            icon={AssessmentIcon}
                            label="Fulfilment Accuracy Rate"
                            percentage={summary.fulfilmentAccuracyRate || 0}
                            color={theme.palette.dispatch.green} // Use theme palette
                            tooltip="Fulfilment Accuracy Rate represents the percentage of dispatched orders that were fulfilled correctly without errors or delays (Expecting Next Day Dispatch)."
                            sx={{ flexGrow: 1 }}
                        />
                    </Grid>

                    {/* Other Cards */}
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={2} sx={{ height: '100%' }}>
                            {/* First Row: Total New Orders and Dispatched Today */}
                            <Grid container item spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <SummaryCardWithAreaChart
                                        icon={StorageIcon}
                                        label="Total New Orders"
                                        value={summary.newOrdersTotal || 0}
                                        color={theme.palette.primary.main} // Use theme palette
                                        tooltip="Total number of new orders today."
                                        percentageChange={summary.newOrdersPercentageChange}
                                        chartData={prepareTinyAreaChartData(summary.newOrdersLast7Days)}
                                        badge="Today"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SummaryCardWithAreaChart
                                        icon={LocalShippingIcon}
                                        label="Dispatched"
                                        value={summary.dispatched || 0}
                                        color={theme.palette.dispatch.green} // Use theme palette
                                        tooltip="Total number of orders dispatched today."
                                        percentageChange={summary.dispatchedPercentageChange}
                                        chartData={prepareTinyAreaChartData(summary.dispatchedLast7Days)}
                                        badge="Today"
                                    />
                                </Grid>
                            </Grid>

                            {/* Second Row: Required Dispatch Today and Cancelled Orders Today */}
                            <Grid container item spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <RequiredDispatchProgressCard
                                        icon={PriorityHighIcon}
                                        label="Required Dispatch"
                                        requiredDispatch={summary.requiredDispatchedToday || 0}
                                        reshipOrders={summary.reshipOrdersToday || 0} // Pass the new metric
                                        progressPercentage={summary.progressPercentage || 0}
                                        tooltip="Overall progress of order processing."
                                        badge="Today"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SummaryCardWithAreaChart
                                        icon={CancelIcon}
                                        label="Cancelled Orders"
                                        value={summary.cancelledOrdersToday || 0}
                                        color={theme.palette.dispatch.red} // Use theme palette
                                        tooltip="Number of orders cancelled today."
                                        percentageChange={summary.cancelledPercentageChange}
                                        chartData={prepareTinyAreaChartData(summary.cancelledLast7Days)}
                                        badge="Today"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Clients Statistics */}
                <Box sx={{ mb: 2 }}>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                        Client's Statistics
                    </Typography>
                </Box>
                {isMobile ? (
                    // Render clients as cards on mobile
                    <Grid container spacing={2}>
                        {clients.map((client) => {
                            // Calculate displayProgress: 100 if both requiredDispatchToday and dispatchedOrders are 0
                            const displayProgress =
                                (client.requiredDispatchToday === 0 && client.dispatchedOrders === 0)
                                    ? 100
                                    : (client.progressPercentage > 100 ? 100 : client.progressPercentage || 0);

                            const dynamicColor = getColorByPercentage(displayProgress, theme.palette.dispatch);
                            const dispatchColor = getDispatchColor(client.requiredDispatchToday, theme.palette.dispatch);

                            return (
                                <Grid item xs={12} key={client.clientName}>
                                    <Paper sx={{ p: 2, borderRadius: 2 }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
                                                {client.clientName}
                                            </Typography>
                                            <IconButton
                                                onClick={(e) => handleCutoffTimeClick(e, client)}
                                                aria-label={`View cutoff times for ${client.clientName}`}
                                            >
                                                <AlarmIcon />
                                            </IconButton>
                                        </Box>
                                        <Box sx={{ mt: 1 }}>
                                            {/* Required Dispatch Today */}
                                            <Button
                                                onClick={() => handleDispatchTodayClick(client.clientName)}
                                                sx={{
                                                    textTransform: 'none',
                                                    fontWeight: 'bold',
                                                    padding: 0,
                                                    minWidth: 'auto',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    '&:hover .info-icon': {
                                                        color: dispatchColor,
                                                    },
                                                }}
                                                aria-label={`View Required Dispatch Today Orders for ${client.clientName}`}
                                            >
                                                {/* Separate Label and Number for Consistent Font Size */}
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        color: theme.palette.dispatch.red,
                                                    }}
                                                >
                                                    Required Dispatch Today:
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        ml: 0.5,
                                                        color: dispatchColor,
                                                        fontWeight: 'bold'
                                                    }}
                                                >
                                                    {client.requiredDispatchToday || 0}
                                                </Typography>
                                                <InfoOutlinedIcon
                                                    fontSize="small"
                                                    className="info-icon"
                                                    sx={{ color: 'text.secondary' }}
                                                />
                                            </Button>

                                            {/* Re-Ship Orders Today */}
                                            {client.reshipOrdersToday > 0 && (
                                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{
                                                            fontWeight: 'bold',
                                                            color: theme.palette.error.main,
                                                        }}
                                                    >
                                                        Required Re-Ship Orders Today:
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        sx={{
                                                            ml: 0.5,
                                                            color: theme.palette.error.main,
                                                            fontWeight: 'bold'
                                                        }}
                                                    >
                                                        {client.reshipOrdersToday || 0}
                                                    </Typography>
                                                    <BlinkingWarningIcon />
                                                </Box>
                                            )}

                                            {/* Dispatched Orders Today */}
                                            <Typography sx={{ color: theme.palette.dispatch.green, mt: 1 }}>
                                                Dispatched Orders Today: {client.dispatchedOrders || 0}
                                            </Typography>

                                            {/* Progress */}
                                            <Typography sx={{ mt: 1 }}>
                                                Progress: {displayProgress}%
                                            </Typography>
                                        </Box>
                                        {/* Progress Bar */}
                                        <Box sx={{ mt: 1 }}>
                                            <LinearProgress
                                                variant="determinate"
                                                value={displayProgress}
                                                sx={{
                                                    height: 8,
                                                    borderRadius: 5,
                                                    [`& .MuiLinearProgress-bar`]: {
                                                        backgroundColor: dynamicColor,
                                                        transition: 'background-color 0.3s ease',
                                                    },
                                                    transition: 'background-color 0.3s ease',
                                                }}
                                            />
                                        </Box>
                                    </Paper>
                                </Grid>
                            );
                        })}
                    </Grid>
                ) : (
                    // Render clients as table on larger screens
                    <ClientTable
                        clients={clients}
                        onDispatchTodayClick={handleDispatchTodayClick}
                        onCutoffTimeClick={handleCutoffTimeClick}
                    />
                )}

                {/* Popover for Cutoff Times */}
                <Popover
                    open={openCutoffPopover}
                    anchorEl={cutoffPopoverAnchorEl}
                    onClose={handleCutoffPopoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    PaperProps={{
                        elevation: 3,
                        sx: {
                            padding: 2,
                            maxWidth: { xs: '90%', sm: 500, md: 600 }, // Responsive widths
                            width: '100%',
                        },
                    }}
                >
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            Cutoff Times for {cutoffClient?.clientName}
                        </Typography>
                        {cutoffClient?.cutoffTimes && cutoffClient.cutoffTimes.length > 0 ? (
                            <Box sx={{ overflowX: 'auto' }}>
                                <Table size="small" sx={{ minWidth: 500 }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Shipping Carrier</TableCell>
                                            <TableCell>Shipping Method</TableCell>
                                            <TableCell>Condition</TableCell>
                                            <TableCell>Cutoff Time</TableCell>
                                            <TableCell>Day Offset</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {cutoffClient.cutoffTimes.map((cutoff, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{cutoff.shipping_carrier}</TableCell>
                                                <TableCell>{cutoff.shipping_method}</TableCell>
                                                <TableCell>
                                                    {cutoff.shipping_method_condition === 'equals' ? '==' : '!='}
                                                </TableCell>
                                                <TableCell>{formatCutoffTime(cutoff.cutoff_time)}</TableCell>
                                                <TableCell>
                                                    {cutoff.cutoff_day_offset > 0 ? cutoff.cutoff_day_offset : '-'}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        ) : (
                            <Typography variant="body2">
                                No cutoff times available.
                            </Typography>
                        )}
                    </Box>
                </Popover>

                {/* Modal for Required Dispatch Today Orders */}
                <Dialog
                    open={modalOpen}
                    onClose={handleModalClose}
                    fullWidth
                    maxWidth="lg"
                    aria-labelledby="dispatch-orders-dialog-title"
                    fullScreen={isMobile} // Correct usage
                >
                    <DialogTitle id="dispatch-orders-dialog-title">
                        Required Dispatch Today Orders for {selectedClientName}
                    </DialogTitle>
                    <DialogContent dividers>
                        {dispatchLoading ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    minHeight: '200px',
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        ) : dispatchError ? (
                            <Typography variant="body1" color="error">
                                {dispatchError}
                            </Typography>
                        ) : dispatchOrders.length > 0 ? (
                            <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Order Number</TableCell>
                                            <TableCell>Create Date</TableCell>
                                            <TableCell>Shipping Carrier</TableCell>
                                            <TableCell>Shipping Method</TableCell>
                                            <TableCell>Issue Status</TableCell>
                                            <TableCell>Issue/Error</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {dispatchOrders.map((order) => (
                                            <TableRow key={order.orderId}>
                                                <TableCell>{order.orderNumber}</TableCell>
                                                <TableCell>
                                                    {formatDateTime(order.createdAt)}
                                                </TableCell>
                                                <TableCell>{order.shippingCarrier}</TableCell>
                                                <TableCell>{order.shippingMethod}</TableCell>
                                                <TableCell>
                                                    {order.issueStatus ? (
                                                        <Chip
                                                            label={order.issueStatus}
                                                            sx={{
                                                                backgroundColor: getChipStyles(order.issueStatus),
                                                                color: '#fff',
                                                            }}
                                                        />
                                                    ) : null}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => handleAddIssueClick(order)}
                                                        aria-label="Add Issue"
                                                    >
                                                        <ErrorOutline />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <Typography variant="body1">
                                No required dispatch today orders found for this client.
                            </Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleModalClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Issue Dialog */}
                {selectedOrder && (
                    <IssueDialog
                        open={openIssueDialog}
                        onClose={handleIssueDialogClose}
                        order={selectedOrder}
                        onIssueAdded={handleIssueAdded}
                    />
                )}
            </Box>
        </Layout>
    );
}

export default Dashboard;
